<template>
    <div>
        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
            <b-card no-body header-bg-variant="bluenavy" header="Gestione Iscrizioni CONI" header-text-variant="white"
                header-tag="h2" border-variant="bluenavy">
                <b-tabs card justified v-model="tabIndex">
                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Da Inviare"
                        :title-link-class="linkClass(0)" active>
                        <SmartTable ref="dainviare" url="/conisubscriptions/index" base_path="/conisubscriptions"
                            :fixed_filters="[{ name: 'sendstate', filter: 'none' }]" :has_details="true"
                            custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Sodalizi da
                                        inviare</span></h2>
                            </template>

                            <template v-slot:before-filters>
                                <div class="pb-3">
                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioAnagrafiche('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Anagrafiche Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioAffiliazioni('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Affiliazioni Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioRecapiti('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Recapiti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioImpianti('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Impianti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioConsiglio('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Consiglio Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioTesserati('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Tesserati Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioDocumenti('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Documenti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioPresidente('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Presidente Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioPresVice('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Pres e Vice Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioFlusso('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Flusso Selezionati
                                    </a>
                                </div>

                                <div class="pb-3 text-right">
                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoAnagrafiche('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Anagrafiche
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoAffiliazioni('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Affiliazioni
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoRecapiti('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Recapiti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoImpianti('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Impianti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoConsiglio('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Consiglio
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoTesserati('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Tesserati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoDocumenti('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Documenti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoPresidente('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Presidente
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoPresVice('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Pres e Vice
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoFlusso('dainviare')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Flusso
                                    </a>
                                </div>
                            </template>

                            <template v-slot:th-coniaffiliationsendedflag="slotProps">
                                <th v-if="slotProps.values.field.is_visible_for_index" class="bg-primary text-nowrap">
                                    <span class="text-muted text-white">&nbsp;</span>
                                </th>
                            </template>

                            <template v-slot:th-hasenrolledusersflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idaffiliationdocumentsloadedstate="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-coniaffiliationsendedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div
                                        class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                        <span v-if="slotProps.values.item.newaffiliationflag === 'Si'"
                                            class="badge pill bg-info mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-plus-square"></i> PRIMA AFFILIAZIONE</span>

                                        <span v-if="slotProps.values.item.coniaffiliationsendedflag === 'Si'"
                                            class="badge pill bg-primary mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-satellite-dish text-white"></i> INVIATO AL CONI</span>
                                        <span v-if="slotProps.values.item.coniaffiliationsendedflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-window-close text-white"></i> NON INVIATO AL CONI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'Si'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users text-white"></i> TESSERATI ATTIVI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users-slash text-white"></i> NO TESSERATI ATTIVI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Tutti i documenti obbligatori sono stati caricati'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-alt text-white"></i> DOCUMENTI COMPLETI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Mancano alcuni documenti tra quelli obbligatori'"
                                            class="badge pill bg-warning mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-contract text-white"></i> DOCUMENTI PARZIALI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Nessun documento obbligatorio caricato'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-excel text-white"></i> NESSUN DOCUMENTO</span>

                                        <span v-if="slotProps.values.item.cipflag === 'Si'" class="badge pill mb-1"
                                            style="font-size: 0.9em; background-color: #FF58F6;"><i
                                                class="fab fa-accessible-icon text-white"></i> CIP</span>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-hasenrolledusersflag="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-idaffiliationdocumentsloadedstate="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-affiliationdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-constitutiveactdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-taxcodedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-identitycarddocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutemodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-boardmodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:item-details="slotProps">
                                <ManageConiSportingClub :idsportingclub="slotProps.values.item.id"
                                    :annualityid="slotProps.values.item.annualityid" v-if="slotProps.values.item.show">
                                </ManageConiSportingClub>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-primary"
                                        title="Gestione Sodalizio" alt="Gestione Sodalizio"
                                        @click.prevent="$router.push('/sportingclub/gestione/' + slotProps.values.item.id)">
                                        <i class="fas fa-tasks"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Inviati"
                        :title-link-class="linkClass(1)">
                        <SmartTable ref="inviati" url="/conisubscriptions/index" base_path="/conisubscriptions"
                            :fixed_filters="[{ name: 'sendstate', filter: 'full' }]" :has_details="true"
                            custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Sodalizi
                                        inviati</span></h2>
                            </template>

                            <template v-slot:before-filters>
                                <div class="pb-3">
                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioAnagrafiche('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Anagrafiche Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioAffiliazioni('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Affiliazioni Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioRecapiti('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Recapiti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioImpianti('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Impianti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioConsiglio('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Consiglio Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioTesserati('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Tesserati Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioDocumenti('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Documenti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioPresidente('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Presidente Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioPresVice('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Pres e Vice Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioFlusso('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Flusso Selezionati
                                    </a>
                                </div>

                                <div class="pb-3 text-right">
                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoAnagrafiche('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Anagrafiche
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoAffiliazioni('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Affiliazioni
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoRecapiti('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Recapiti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoImpianti('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Impianti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoConsiglio('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Consiglio
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoTesserati('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Tesserati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoDocumenti('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Documenti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoPresidente('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Presidente
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoPresVice('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Pres e Vice
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoFlusso('inviati')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Flusso
                                    </a>
                                </div>
                            </template>

                            <template v-slot:th-coniaffiliationsendedflag="slotProps">
                                <th v-if="slotProps.values.field.is_visible_for_index" class="bg-primary text-nowrap">
                                    <span class="text-muted text-white">&nbsp;</span>
                                </th>
                            </template>

                            <template v-slot:th-hasenrolledusersflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idaffiliationdocumentsloadedstate="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-coniaffiliationsendedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div
                                        class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                        <span v-if="slotProps.values.item.newaffiliationflag === 'Si'"
                                            class="badge pill bg-info mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-plus-square"></i> PRIMA AFFILIAZIONE</span>

                                        <span v-if="slotProps.values.item.coniaffiliationsendedflag === 'Si'"
                                            class="badge pill bg-primary mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-satellite-dish text-white"></i> INVIATO AL CONI</span>
                                        <span v-if="slotProps.values.item.coniaffiliationsendedflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-window-close text-white"></i> NON INVIATO AL CONI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'Si'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users text-white"></i> TESSERATI ATTIVI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users-slash text-white"></i> NO TESSERATI ATTIVI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Tutti i documenti obbligatori sono stati caricati'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-alt text-white"></i> DOCUMENTI COMPLETI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Mancano alcuni documenti tra quelli obbligatori'"
                                            class="badge pill bg-warning mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-contract text-white"></i> DOCUMENTI PARZIALI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Nessun documento obbligatorio caricato'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-excel text-white"></i> NESSUN DOCUMENTO</span>

                                        <span v-if="slotProps.values.item.cipflag === 'Si'" class="badge pill mb-1"
                                            style="font-size: 0.9em; background-color: #FF58F6;"><i
                                                class="fab fa-accessible-icon text-white"></i> CIP</span>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-hasenrolledusersflag="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-idaffiliationdocumentsloadedstate="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-affiliationdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-constitutiveactdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-taxcodedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-identitycarddocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutemodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-boardmodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:item-details="slotProps">
                                <ManageConiSportingClub :idsportingclub="slotProps.values.item.id"
                                    :annualityid="slotProps.values.item.annualityid" v-if="slotProps.values.item.show">
                                </ManageConiSportingClub>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-primary"
                                        title="Gestione Sodalizio" alt="Gestione Sodalizio"
                                        @click.prevent="$router.push('/sportingclub/gestione/' + slotProps.values.item.id)">
                                        <i class="fas fa-tasks"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Invii Parziali"
                        :title-link-class="linkClass(2)">
                        <SmartTable ref="inviiparziali" url="/conisubscriptions/index" base_path="/conisubscriptions"
                            :fixed_filters="[{ name: 'sendstate', filter: 'partial' }]" :has_details="true"
                            custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Sodalizi inviati
                                        parzialmente</span></h2>
                            </template>

                            <template v-slot:before-filters>
                                <div class="pb-3">
                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioAnagrafiche('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Anagrafiche Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioAffiliazioni('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Affiliazioni Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioRecapiti('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Recapiti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioImpianti('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Impianti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioConsiglio('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Consiglio Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioTesserati('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Tesserati Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioDocumenti('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Documenti Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioPresidente('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Presidente Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioPresVice('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Pres e Vice Selezionati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3"
                                        @click.prevent="invioFlusso('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Flusso Selezionati
                                    </a>
                                </div>

                                <div class="pb-3 text-right">
                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoAnagrafiche('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Anagrafiche
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoAffiliazioni('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Affiliazioni
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoRecapiti('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Recapiti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoImpianti('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Impianti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoConsiglio('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Consiglio
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoTesserati('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Tesserati
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoDocumenti('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Documenti
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoPresidente('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Presidente
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoPresVice('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Pres e Vice
                                    </a>

                                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3"
                                        @click.prevent="invioMassivoFlusso('inviiparziali')">
                                        <i class="fas fa-file-upload"></i>
                                        Invio Massivo Flusso
                                    </a>
                                </div>
                            </template>

                            <template v-slot:th-coniaffiliationsendedflag="slotProps">
                                <th v-if="slotProps.values.field.is_visible_for_index" class="bg-primary text-nowrap">
                                    <span class="text-muted text-white">&nbsp;</span>
                                </th>
                            </template>

                            <template v-slot:th-hasenrolledusersflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idaffiliationdocumentsloadedstate="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-coniaffiliationsendedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div
                                        class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                        <span v-if="slotProps.values.item.newaffiliationflag === 'Si'"
                                            class="badge pill bg-info mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-plus-square"></i> PRIMA AFFILIAZIONE</span>

                                        <span v-if="slotProps.values.item.coniaffiliationsendedflag === 'Si'"
                                            class="badge pill bg-primary mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-satellite-dish text-white"></i> INVIATO AL CONI</span>
                                        <span v-if="slotProps.values.item.coniaffiliationsendedflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-window-close text-white"></i> NON INVIATO AL CONI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'Si'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users text-white"></i> TESSERATI ATTIVI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users-slash text-white"></i> NO TESSERATI ATTIVI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Tutti i documenti obbligatori sono stati caricati'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-alt text-white"></i> DOCUMENTI COMPLETI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Mancano alcuni documenti tra quelli obbligatori'"
                                            class="badge pill bg-warning mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-contract text-white"></i> DOCUMENTI PARZIALI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Nessun documento obbligatorio caricato'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-excel text-white"></i> NESSUN DOCUMENTO</span>

                                        <span v-if="slotProps.values.item.cipflag === 'Si'" class="badge pill mb-1"
                                            style="font-size: 0.9em; background-color: #FF58F6;"><i
                                                class="fab fa-accessible-icon text-white"></i> CIP</span>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-hasenrolledusersflag="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-idaffiliationdocumentsloadedstate="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-affiliationdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-constitutiveactdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-taxcodedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-identitycarddocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutemodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-boardmodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:item-details="slotProps">
                                <ManageConiSportingClub :idsportingclub="slotProps.values.item.id"
                                    :annualityid="slotProps.values.item.annualityid" v-if="slotProps.values.item.show">
                                </ManageConiSportingClub>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-primary"
                                        title="Gestione Sodalizio" alt="Gestione Sodalizio"
                                        @click.prevent="$router.push('/sportingclub/gestione/' + slotProps.values.item.id)">
                                        <i class="fas fa-tasks"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Monitoraggio"
                        :title-link-class="linkClass(3)">
                        <SmartTable ref="monitoraggio" url="/conimonitoring/index" :is_dialog="true"
                            base_url="/conimonitoring" base_path="/" :fixed_filters="[]" custom_class="mx-0 px-0"
                            v-on:close-modal="closeModal">
                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Monitoraggio
                                        Invii</span></h2>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a v-if="!slotProps.values.item.errormessage" href="javascript:void(0);"
                                        class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Dettagli"
                                        alt="Dettagli"
                                        @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                        <i class="fas fa-eye"></i>
                                    </a>

                                    <a v-if="slotProps.values.item.errormessage" href="javascript:void(0);"
                                        class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Dettagli"
                                        alt="Dettagli"
                                        @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import SmartTable from "@/view/components/SmartTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from 'sweetalert2';
import ApiService from "@/core/services/api.service";

export default {
    data() {
        return {
            tabIndex: 0,
            loading: false,
        };
    },

    components: {
        SmartTable,
        ManageConiSportingClub: () => import("@/view/components/ManageConiSportingClub.vue"),
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Affiliazioni", route: "/sportingclub/gestione-iscrizioni-coni" },
            { title: "Gestione Iscrizioni CONI" }
        ]);
    },

    methods: {
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5']
            } else {
                return ['bg-primary', 'text-white', 'font-size-h5']
            }
        },

        closeModal() {
        },

        invioAnagrafiche(table) {
            Swal.fire({
                title: 'Conferma Invio Anagrafiche',
                text: "Sei sicuro di voler inviare le Anagrafiche dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-sportingclubs';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            });
        },

        invioMassivoAnagrafiche(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Anagrafiche',
                text: "Sei sicuro di voler inviare le Anagrafiche massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-sportingclubs';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            });
        },

        invioAffiliazioni(table) {
            Swal.fire({
                title: 'Conferma Invio Affiliazioni',
                text: "Sei sicuro di voler inviare le Affiliazioni dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-affiliations';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            });
        },

        invioMassivoAffiliazioni(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Affiliazioni',
                text: "Sei sicuro di voler inviare le Affiliazioni massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-affiliations';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            });
        },

        invioRecapiti(table) {
            Swal.fire({
                title: 'Conferma Invio Recapiti',
                text: "Sei sicuro di voler i Recapiti dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-details';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoRecapiti(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Recapiti',
                text: "Sei sicuro di voler i Recapiti massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-details';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioImpianti(table) {
            Swal.fire({
                title: 'Conferma Invio Impianti',
                text: "Sei sicuro di voler gli Impianti dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-facilities';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoImpianti(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Impianti',
                text: "Sei sicuro di voler gli Impianti massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-facilities';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioConsiglio(table) {
            Swal.fire({
                title: 'Conferma Invio Consiglio',
                text: "Sei sicuro di voler il Consiglio dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',

                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder: 'Invia con le date di ruolo originali?',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-council';
                        let data = { values: values, original_date: result.value };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoConsiglio(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Consiglio',
                text: "Sei sicuro di voler il Consiglio massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-council';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioTesserati(table) {
            Swal.fire({
                title: 'Conferma Invio Tesserati',
                text: "Sei sicuro di voler i Tesserati dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-enrolled-users';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoTesserati(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Tesserati',
                text: "Sei sicuro di voler i Tesserati massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-enrolled-users';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioDocumenti(table) {
            Swal.fire({
                title: 'Conferma Invio Documenti',
                text: "Sei sicuro di voler i Documenti dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-documents';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoDocumenti(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Documenti',
                text: "Sei sicuro di voler i Documenti massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-documents';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioPresidente(table) {
            Swal.fire({
                title: 'Conferma Invio Presidente',
                text: "Sei sicuro di voler il Presidente dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',

                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder: 'Invia con le date di ruolo originali?',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-president';
                        let data = { values: values, original_date: result.value };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoPresidente(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Presidente',
                text: "Sei sicuro di voler inviare il Presidente massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-president';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioPresVice(table) {
            Swal.fire({
                title: 'Conferma Invio Presidente e Vicepresidente',
                text: "Sei sicuro di voler il Presidente e Vicepresidente dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',

                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder: 'Invia con le date di ruolo originali?',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-president-and-vicepresident';
                        let data = { values: values, original_date: result.value };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoPresVice(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Presidente e Vicepresidente',
                text: "Sei sicuro di voler inviare il Presidente e Vicepresidente massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-president-and-vicepresident';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioFlusso(table) {
            Swal.fire({
                title: 'Conferma Invio Flusso',
                text: "Sei sicuro di voler il Flusso Completo dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/conimonitoring/send-selected-flux';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoFlusso(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Flusso',
                text: "Sei sicuro di voler il Flusso massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/conimonitoring/send-massive-flux';
                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },
    },
};
</script>
