<template>
    <div>
        Nuovo Comitato - In allestimento
    </div>
</template>

<script>
import SmartForm from "@/view/components/SmartForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartForm,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Comitati", route: "/committee/modifiche-in-sospeso" },
            { title: "Nuovo Comitato" }
        ]);
    }
};
</script>
