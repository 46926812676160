<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <div
            :class="is_wizard ? 'card card-custom card-transparent topform' + topFormClassId : 'card card-custom card-stretch gutter-b topform' + topFormClassId">
            <div :class="is_wizard ? 'card-header border-0' : 'card-header border-0 py-5'">
                <div class="card-title">
                    <slot name="title">
                        <h3 class="card-label">
                            {{ labels.title }}
                            <br>
                            <a v-if="!is_dialog && show_list_button" href="#"
                                @click.prevent="route('elenco header', base_path + '/index', 'push')"
                                class="mt-2 btn btn-primary btn-sm font-weight-bolder">
                                <i class="fas fa-list icon-sm"></i>
                                Elenco {{ labels.plural }}
                            </a>
                            <a v-if="is_dialog" href="#" @click.prevent="closeDialog()"
                                class="mt-2 btn btn-danger btn-sm font-weight-bolder">
                                <i class="fas fa-window-close icon-sm"></i>
                                Chiudi
                            </a>
                            <i class="mr-2" v-if="!is_dialog"></i>
                            <small class="" v-if="!is_dialog">{{ labels.description }}</small><br>
                        </h3>
                    </slot>
                </div>
                <div class="card-toolbar">
                    <slot name="toolbar" v-bind:values="{ doAction: doAction, actions: actions, id: id }">
                        <a v-if="((is_wizard && action.name !== 'save') || (!is_wizard)) &&
                            (
                                (is_dialog && action.name === 'save' && form_type === 'new')
                                ||
                                (is_dialog && action.name === 'save' && form_type === 'edit')
                                ||
                                (is_dialog && action.name === 'save' && form_type === 'copy')
                                ||
                                (is_dialog && action.name === 'destroy' && form_type === 'delete')
                                ||
                                (is_dialog && action.name === 'view' && form_type === 'view')
                                ||
                                !is_dialog
                            )" v-for="(action, index) in actions" href="#" @click.prevent="doAction(action.name)"
                            :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder' + ((index < (actions.length - 1)) ? ' mr-3' : '')">
                            <i v-if="action.icon" :class="action.icon"></i>
                            {{ action.label }}
                        </a>
                    </slot>
                </div>
            </div>
            <div :class="is_wizard ? 'card-body p-0' : 'card-body'">
                <b-alert v-if="show_alert" variant="danger" dismissible fade :show="message && show_alert"
                    @dismissed="show_alert = false">
                    {{ this.message }}
                </b-alert>

                <b-alert
                    v-if="typeof errors['__main_error_'] !== 'undefined' && typeof errors['__main_error_'] !== 'Object' && typeof errors['__main_error_']['unique'] !== 'undefined'"
                    variant="danger" dismissible fade :show="true" @dismissed="false">
                    {{ errors['__main_error_']['unique'][0] }}
                </b-alert>

                <b-alert v-if="show_success" variant="success" dismissible fade :show="show_success"
                    @dismissed="show_success = false">
                    Dati salvati correttamente
                </b-alert>

                <div v-if="is_wizard" class="wizard wizard-4" id="kt_wizard" data-wizard-state="step-first"
                    data-wizard-clickable="true">
                    <!--begin: Wizard Nav-->
                    <div
                        :class="'wizard-nav border' + (wizardHasErrors() ? ' border-danger' : ' border-primary') + ' border-top-0 border-right-0 border-left-0'">
                        <div class="wizard-steps">
                            <div v-show="step.visible" v-for="(step, index) in steps"
                                :class="'wizard-step border' + (stepHasErrors(step) ? ' border-danger' : ' border-primary') + ' border-bottom-0'"
                                data-wizard-type="step" :data-wizard-state="index === 0 ? 'current' : ''">
                                <div class="wizard-wrapper">
                                    <div class="wizard-number"
                                        :style="stepHasErrors(step) ? 'background-color: red; color: white;' : ''">
                                        {{ step.step }}
                                    </div>
                                    <div class="wizard-label">
                                        <div class="wizard-title" :style="stepHasErrors(step) ? 'color: red;' : ''">
                                            {{ step.title }}
                                        </div>
                                        <div class="wizard-desc">
                                            {{ step.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Nav -->

                    <!--begin: Wizard Body-->
                    <div class="card card-custom card-shadowless rounded-top-0">
                        <div class="card-body p-0 border border-primary border-top-0 rounded-bottom">
                            <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                                <div class="col-xl-11 col-xxl-11 col-md-11 col-lg-11">
                                    <!--begin: Wizard Form-->
                                    <form class="form mt-0 mt-lg-10">

                                        <!--begin: Wizard Step 1-->
                                        <div v-show="step.visible" v-for="(step, index) in steps" class="pb-5"
                                            data-wizard-type="step-content"
                                            :data-wizard-state="index === 0 ? 'current' : ''">
                                            <div class="mb-10 font-weight-bold text-dark">
                                                {{ step.header }}
                                            </div>

                                            <FormFields :form_type="form_type" :errors="errors"
                                                v-model="step.formFields" v-on:remove-error="removeError" />

                                        </div>
                                        <!--end: Wizard Step 1-->

                                        <!--begin: Wizard Actions -->
                                        <div class="d-flex justify-content-between border-top pt-10">
                                            <div class="mr-2">
                                                <button
                                                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                    data-wizard-type="action-prev">
                                                    <i class="ki ki-arrow-back icon-sm"></i>
                                                    Indietro
                                                </button>
                                            </div>
                                            <div>
                                                <button v-on:click="submit"
                                                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                                    data-wizard-type="action-submit">
                                                    Invia
                                                    <i class="ki ki-check icon-sm"></i>
                                                </button>
                                                <button
                                                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                                                    data-wizard-type="action-next">
                                                    Avanti
                                                    <i class="ki ki-arrow-next icon-sm"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <!--end: Wizard Actions -->

                                    </form>
                                </div>
                                <!--end: Wizard Form-->
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Body-->

                </div>

                <form v-if="!is_wizard" class="form">

                    <FormFields ref="formfields" :form_type="form_type" :errors="errors" v-model="fields"
                        v-on:remove-error="removeError" />

                </form>
            </div>
            <slot name="footer" v-bind:values="{ doAction: doAction, actions: actions, id: id }">
                <div class="card-footer border-0 py-5">
                    <div class="d-flex flex-wrap justify-content-between">
                        <div v-if="!is_dialog">
                            <a href="#" @click.prevent="route('elenco footer', base_path + '/index', 'push')"
                                class="mt-2 btn btn-primary font-weight-bolder">
                                <i class="fas fa-list"></i>
                                Elenco {{ labels.plural }}
                            </a>
                        </div>
                        <div v-if="is_dialog">
                            <a href="#" @click.prevent="closeDialog()" class="mt-2 btn btn-danger font-weight-bolder">
                                <i class="fas fa-window-close"></i>
                                Chiudi
                            </a>
                        </div>
                        <div>
                            <a v-if="((is_wizard && action.name !== 'save') || (!is_wizard)) &&
                                (
                                    (is_dialog && action.name === 'save' && form_type === 'new')
                                    ||
                                    (is_dialog && action.name === 'save' && form_type === 'edit')
                                    ||
                                    (is_dialog && action.name === 'save' && form_type === 'copy')
                                    ||
                                    (is_dialog && action.name === 'destroy' && form_type === 'delete')
                                    ||
                                    (is_dialog && action.name === 'view' && form_type === 'view')
                                    ||
                                    !is_dialog
                                )" v-for="(action, index) in actions" href="#" @click.prevent="doAction(action.name)"
                                :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder' + ((index < (actions.length - 1)) ? ' mr-3' : '')">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{ action.label }}
                            </a>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </b-overlay>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import FormFields from "@/view/components/FormFields.vue";
import ApiService from "@/core/services/api.service";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from 'sweetalert2';

export default {
    name: 'SmartForm',

    props: {
        item_id: {
            type: [String, Number],
            default: null
        },

        fixed_filters: {
            type: Array,
            default: () => []
        },

        is_dialog: {
            type: Boolean,
            default: false
        },

        options_url: {
            type: String,
            default: null
        },

        store_url: {
            type: String,
            default: null
        },

        values_url: {
            type: String,
            default: null
        },

        update_url: {
            type: String,
            default: null
        },

        copy_url: {
            type: String,
            default: null
        },

        delete_url: {
            type: String,
            default: null
        },

        view_url: {
            type: String,
            default: null
        },

        destroy_url: {
            type: String,
            default: null
        },

        after_store_url: {
            type: String,
            default: null
        },

        after_update_url: {
            type: String,
            default: null
        },

        base_url: {
            type: String,
            default: null
        },

        base_path: {
            type: String,
            default: null
        },

        form_type: {
            type: String,
            default: null
        },

        form_variant: {
            type: String,
            default: null
        },

        redirect_after_save: {
            type: Boolean,
            default: true
        },

        show_list_button: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            first_loading: true,
            topFormClassId: '',
            id: '',
            is_wizard: false,
            steps: [],
            availableSteps: [],
            loading_options: false,
            loading: false,
            fields: [],
            actions: [],
            labels: [],

            show_alert: false,
            show_success: false,
            message: '',
            errors: [],

            item: {},
            options_loaded: false,
            values_loaded: false,
        };
    },

    async created() {
        this.topFormClassId = this.generateRandomId();

        this.id = (((typeof this.item_id !== 'undefined') && (this.item_id)) ? this.item_id : this.$route.params.id);

        await this.getOptions();

        if (this.form_type === 'edit') {
            this.getValues();
        }
        else if (this.form_type === 'copy') {
            this.getValues();
        }
        else if (this.form_type === 'delete') {
            this.getValues();
        }
        else if (this.form_type === 'view') {
            this.getValues();
        }
        else if (this.id || this.item_id) {
            this.getValues();
        }
    },
    mounted() {
    },
    updated() {
        this.$nextTick(function () {
            if (this.is_wizard) {
                let vm = this;

                const wizard = new KTWizard("kt_wizard", {
                    startStep: 1, // initial active step number
                    clickableSteps: true // allow step clicking
                });

                // Validation before going to next page
                wizard.on("beforeNext", function (/*wizardObj*/) {
                    // validate the form and use below function to stop the wizard's step
                    // wizardObj.stop();
                });

                // Change event
                wizard.on("change", function (wizard) {
                    let currentStep = wizard.getStep();

                    let newStep = wizard.getNewStep();

                    let availableSteps = vm.availableSteps;
                    if (!availableSteps.length) {
                        availableSteps = [];

                        let count = 0;
                        for (let i in this.steps) {
                            availableSteps.push(count);

                            count++;
                        }
                    }

                    currentStep--;
                    newStep--;

                    let step;
                    if (currentStep !== newStep) {
                        step = availableSteps.find(item => item === newStep);
                    }

                    vm.scrollTop();

                    if (typeof step !== 'undefined') {
                        return true;
                    }
                    else {
                        let i = availableSteps.findIndex(item => item === currentStep);

                        if (newStep > currentStep) {
                            i++;
                            let found = false;
                            while (i < availableSteps.length && !found) {
                                step = availableSteps[i];
                                if (step > currentStep) {
                                    found = true;
                                }
                                else {
                                    i++;
                                }
                            }

                            if (found) wizard.goTo(step + 1);

                            return found;
                        }

                        if (newStep < currentStep) {
                            i--;
                            let found = false;
                            while (i > 0 && !found) {
                                step = availableSteps[i];
                                if (step < currentStep) {
                                    found = true;
                                }
                                else {
                                    i--;
                                }
                            }

                            if (found) wizard.goTo(step + 2);

                            return found;
                        }

                        return false;
                    }
                });
            }
        })
    },

    watch: {
    },

    methods: {
        route(where, url, type) {
            if (type === 'push') {
                this.$router.push(url).catch(() => { });
            }
            else if (type === 'replace') {
                this.$router.replace(url).catch(() => { });
            }
            else if (type === 'back') {
                this.$router.go(-1);
            }
        },

        closeDialog() {
            this.$emit('close-dialog');
        },

        emptyValues() {
            for (let i in this.fields) {
                this.fields[i].value = null;
            }
        },

        removeError(fieldName) {
        },

        wizardHasErrors() {
            let has_errors = false;

            if (this.is_wizard) {
                for (let i in this.steps) {
                    for (let j in this.steps[i].formFields) {
                        if (typeof this.errors[this.steps[i].formFields[j].name] !== 'undefined') has_errors = true;
                    }
                }
            }

            return has_errors;
        },

        stepHasErrors(step) {
            let has_errors = false;

            if (this.is_wizard) {
                for (let i in step.formFields) {
                    if (typeof this.errors[step.formFields[i].name] !== 'undefined') has_errors = true;
                }
            }

            return has_errors;
        },

        hasSlot(name = 'default') {
            return !!this.$slots[name] || !!this.$scopedSlots[name];
        },

        getFixedFilterQueryString() {
            let filters = "";

            if (typeof this.fixed_filters !== "undefined") {
                for (let i in this.fixed_filters) {
                    if (filters) filters += "&";
                    filters += encodeURIComponent(this.fixed_filters[i].name) + '=' + encodeURIComponent(this.fixed_filters[i].filter);
                }
            }

            return filters;
        },

        async getOptions() {
            this.options_loaded = false;
            this.loading = true;
            this.loading_options = true;

            let url = this.options_url;

            if (!url && this.form_type === 'new') url = this.base_url + '/store';
            if (!url && this.form_type === 'edit') url = this.base_url + '/update';
            if (!url && this.form_type === 'copy') url = this.base_url + '/copy';
            if (!url && this.form_type === 'delete') url = this.base_url + '/delete';
            if (!url && this.form_type === 'view') url = this.base_url + '/view';
            if (this.form_variant) url = this.base_url + '/' + this.form_variant;
            url += '/options';

            let sep = '?';
            if (typeof this.id !== "undefined" && this.id) {
                url += sep + 'id=' + this.id;
                sep = '&';
            }

            let fixedFiltersQueryString = this.getFixedFilterQueryString();
            if (fixedFiltersQueryString) {
                url += sep + fixedFiltersQueryString;
                sep = '&';
            }

            try {
                let response = await ApiService.query(url);

                this.labels = response.data.labels;
                this.fields = response.data.fields;
                this.actions = response.data.actions;
                this.is_wizard = response.data.is_wizard;

                if (this.fields) {
                    for (let i in this.fields) {
                        if (this.fields[i] && this.fields[i].type === 'boolean') {
                            if (this.fields[i].rich === true) {
                                this.fields[i].options = [
                                    { text: 'Si', value: true },
                                    { text: 'No', value: false },
                                ];
                            }
                            else {
                                this.fields[i].options = [
                                    { text: 'Si', value: 1 },
                                    { text: 'No', value: 0 },
                                ];
                            }
                        }

                        if (this.fields[i] && this.fields[i].type === 'date') {
                            this.fields[i].disable_date = function (date) {
                                if (typeof this.maxdate !== "undefined") {
                                    if (this.maxdate === "today") {
                                        return date > new Date(new Date().setHours(0, 0, 0, 0));
                                    }
                                }

                                return false;
                            }.bind(this.fields[i]);
                        }
                    }
                }

                let steps = response.data.steps;

                let fields = [];

                let countStep = 0;
                for (let x in steps) {
                    steps[x].formFields = [];

                    if (typeof steps[x].visible !== "undefined" && steps[x].visible === true) {
                        this.availableSteps.push(countStep);
                    }

                    countStep++;

                    for (let i in steps[x].fields) {
                        for (let j in this.fields) {
                            if (this.fields[j].name === steps[x].fields[i]) {
                                steps[x].formFields.push(this.fields[j]);
                            }
                        }
                    }
                }

                this.steps = steps;

                this.loading = false;
                this.loading_options = false;

                this.$emit('options-loaded');
                this.options_loaded = true;
            }
            catch (error) {
                console.log(error);
            }
        },

        getFieldValue(field) {
            let value = null;

            if ((typeof field.value !== "undefined") || (typeof field.items !== "undefined" && field.items.length)) {
                if (field.type === 'integer' && field.relation && field.relation_type === 'belongsTo') {
                    if (field.value !== null && typeof field.value.id !== "undefined") {
                        value = field.value.id;
                    }
                    else {
                        value = '';
                    }
                }
                else if (field.type === 'has_many' && field.relation && field.relation_type === 'belongsToMany') {
                    let ids = [];

                    if (field.value.length) {
                        for (let j in field.value) {
                            ids.push(field.value[j].id);
                        }
                    }

                    value = ids;
                }
                else if (field.type === 'repeater') {
                    let rows = [];

                    for (let i in field.items) {
                        let values = {};

                        for (let j in field.items[i].fields) {
                            let value = this.getFieldValue(field.items[i].fields[j]);

                            if (typeof value !== "undefined") {
                                values[field.items[i].fields[j].name] = value;
                            }

                            if (field.items[i].fields[j].format === 'password') {
                                value = field.items[i].fields[j].password_confirm;

                                if (typeof value !== "undefined") {
                                    values[field.items[i].fields[j].name + '_confirm'] = value;
                                }
                            }
                        }

                        rows.push(values);
                    }

                    value = rows;
                }
                else if (field.type === 'boolean' && field.rich === true) {
                    value = field.value;

                    value = (field.value === true ? 1 : (field.value === false ? 0 : null));
                }
                else if (typeof field.value !== "undefined" && typeof field.value === 'object' && field.value !== null && typeof field.value.id !== 'undefined' && field.type !== 'file') {
                    value = field.value.id;
                }
                else {
                    value = field.value;
                }
            }

            return value;
        },

        doAction(action) {
            let url = '';

            switch (action) {
                case 'back':
                    this.route('doAction back', '', 'back');
                    break;

                case 'edit':
                    url = '';

                    if (this.edit_url) url = this.edit_url;

                    if (!url) url = this.base_path + '/edit/' + this.id;

                    this.route('doAction edit', url, 'push');
                    break;

                case 'copy':
                    url = '';

                    if (this.copy_url) url = this.copy_url;

                    if (!url) url = this.base_path + '/copy/' + this.id;

                    this.route('doAction copy', url, 'push');
                    break;

                case 'delete':
                    url = '';

                    if (this.delete_url) url = this.delete_url;

                    if (!url) url = this.base_path + '/delete/' + this.id;

                    this.route('doAction delete', url, 'push');
                    break;

                case 'view':
                    url = '';

                    if (this.view_url) url = this.view_url;

                    if (!url) url = this.base_path + '/view/' + this.id;

                    this.route('doAction view', url, 'push');
                    break;

                case 'destroy':
                    Swal.fire({
                        title: 'Conferma eliminazione',
                        text: "Sei sicuro di voler confermare l\'eliminazione?",
                        icon: 'error',
                        showCancelButton: true,
                        showConfirmButton: true,
                        confirmButtonText: 'Conferma',
                        cancelButtonText: 'Annulla',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            url = '';
                            if (this.destroy_url) url = this.destroy_url;
                            if (!url) url = this.base_url + '/destroy';

                            this.loading = true;

                            ApiService.post(url, { id: this.id })
                                .then((response) => {
                                    this.loading = false;

                                    if (response.data.status === 'OK') {
                                        this.$bvToast.toast('I dati sono stati eliminati correttamente.', {
                                            title: 'Dati eliminati correttamente',
                                            variant: 'success',
                                            solid: true
                                        });

                                        this.show_success = true;
                                        this.message = 'I dati sono stati eliminati correttamente.';

                                        this.$emit('values-deleted');

                                        if (this.is_dialog) {
                                            this.$emit('close-dialog');
                                        }
                                        else {
                                            this.route('doAction destroy', this.base_url + '/index', 'push');
                                        }
                                    }

                                    if (response.data.status === 'KO') {
                                        this.$bvToast.toast('Errore nell\'eliminazione dei dati.', {
                                            title: 'Impossibile eliminare i dati',
                                            variant: 'danger',
                                            solid: true
                                        });

                                        this.show_alert = true;
                                        this.message = 'Impossibile eliminare i dati: errore nell\'eliminazione.';
                                    }
                                })
                                .catch(({ response }) => {
                                    this.loading = false;

                                    this.$bvToast.toast('Si è verificato un errore nell\'eliminazione dei dati.', {
                                        title: 'Errore nell\'eliminazione dei dati',
                                        variant: 'danger',
                                        solid: true
                                    });

                                    console.log(response);
                                })
                        }
                    })
                    break;

                case 'save':
                    let values = {};

                    for (var i in this.fields) {
                        let value = this.getFieldValue(this.fields[i]);

                        if (typeof value !== "undefined") {
                            values[this.fields[i].name] = value;
                        }

                        if (this.fields[i].format === 'password') {
                            value = this.fields[i].password_confirm;

                            if (typeof value !== "undefined") {
                                values[this.fields[i].name + '_confirm'] = value;
                            }
                        }
                    }

                    this.loading = true;

                    this.show_alert = false;
                    this.show_success = false;
                    this.message = '';
                    this.errors = [];

                    url = '';

                    let sep = '';

                    if (this.form_type === 'new' && this.store_url) url = this.store_url;

                    if (this.form_type === 'edit' && this.update_url) url = this.update_url;

                    if (this.form_type === 'copy' && this.store_url) url = this.store_url;

                    if (!url && this.form_type === 'new') {
                        url = this.base_url + '/store';
                        sep = '?';
                    }

                    if (!url && this.form_type === 'edit') {
                        url = this.base_url + '/update?id=' + this.id;
                        sep = '&';
                    }

                    if (!url && this.form_type === 'copy') {
                        url = this.base_url + '/store';
                        sep = '?';
                    }

                    if (this.form_variant) {
                        url = this.base_url + '/' + this.form_variant + (this.id ? '?id=' + this.id : '');
                        sep = '?';
                        if (this.id) sep = '&';
                    }

                    let fixedFiltersQueryString = this.getFixedFilterQueryString();
                    if (fixedFiltersQueryString) url += sep + fixedFiltersQueryString;

                    ApiService.post(url, values)
                        .then((response) => {
                            this.loading = false;

                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('I dati sono stati salvati correttamente.', {
                                    title: 'Dati salvati correttamente',
                                    variant: 'success',
                                    solid: true
                                });

                                this.show_success = true;

                                this.item = response.data.item;

                                if (this.form_type === 'new' && this.after_store_url) {
                                    this.$emit('values-saved');

                                    if (this.is_dialog) {
                                        this.$emit('close-dialog');
                                    }
                                    else {
                                        this.route('doAction save', this.after_store_url + this.id, 'replace');
                                    }
                                }
                                else if (this.form_type === 'edit' && this.after_update_url) {
                                    this.$emit('values-updated');

                                    if (this.is_dialog) {
                                        this.$emit('close-dialog');
                                    }
                                    else {
                                        this.route('doAction save edit', this.after_update_url + this.id, 'replace');
                                    }
                                }
                                else {
                                    this.$emit('values-saved');

                                    if (this.is_dialog) {
                                        this.$emit('close-dialog');
                                    }
                                    else if (typeof this.redirect_after_save === "undefined" || this.redirect_after_save) {
                                        this.route('doAction save last', this.base_path + '/edit/' + this.id, 'replace');
                                    }
                                }
                            }

                            if (response.data.status === 'KO') {
                                let message = 'errore non specificato';
                                if (typeof response.data.message !== "undefined" && response.data.message) {
                                    message = response.data.message;
                                }

                                this.$bvToast.toast('Si è verificato un errore nel salvataggio: ' + message + '.', {
                                    title: 'Errore nel salvataggio dei dati',
                                    variant: 'danger',
                                    solid: true
                                });

                                this.show_alert = true;
                                this.message = 'Si è verificato un errore nel salvataggio: ' + message + '.';
                            }

                            this.scrollTop();
                        })
                        .catch(({ response }) => {
                            this.loading = false;

                            if (response.status === 422) {
                                this.show_alert = true;
                                this.message = 'Si è verificato un errore nel salvataggio: controllare i dati inseriti e riprovare.';
                                this.errors = response.data.errors;

                                this.$bvToast.toast('Si è verificato un errore nel salvataggio: controllare i dati inseriti e riprovare.', {
                                    title: 'Errore nel salvare i dati',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                            else {
                                console.log(response);
                            }
                        })
                    break;
            }
        },

        getValues() {
            this.loading = true;
            this.values_loaded = false;

            let url = '';

            let sep = '';
            if (this.form_type === 'edit' && this.values_url) {
                url = this.values_url;
            }

            if (this.form_type === 'copy' && this.values_url) {
                url = this.values_url;
            }

            if (!url && this.form_type === 'edit') {
                url = this.base_url + '/edit?id=' + this.id;
                sep = '&';
            }

            if (!url && this.form_type === 'copy') {
                url = this.base_url + '/copy?id=' + this.id;
                sep = '&';
            }

            if (!url && this.form_type === 'delete') {
                url = this.base_url + '/delete?id=' + this.id;
                sep = '&';
            }

            if (!url && this.form_type === 'view') {
                url = this.base_url + '/view?id=' + this.id;
                sep = '&';
            }

            if (!url && this.form_variant) {
                url = this.base_url + '/' + this.form_variant + '?id=' + this.id;
                sep = '&';
            }

            if (url) {
                let fixedFiltersQueryString = this.getFixedFilterQueryString();
                if (fixedFiltersQueryString) url += sep + fixedFiltersQueryString;

                ApiService.query(url)
                    .then((response) => {
                        if (typeof response.data.item !== "undefined") {
                            if (response.data.item === null) {
                                this.route('getValues', this.base_path + '/index', 'push');
                            }

                            //let fields = JSON.parse(JSON.stringify(this.fields));
                            for (var i in response.data.item) {
                                let field = this.fields.find(function (item) {
                                    return item.name === i;
                                });

                                if (field) {
                                    let field_index = this.fields.findIndex(function (item) {
                                        return item.name === field.name;
                                    });

                                    if (field.type === 'integer' && field.relation && field.relation_type === 'belongsTo') {
                                        let id_option = response.data.item[i];
                                        let option = null;
                                        if (field.filter_options !== null) {
                                            option = field.filter_options.find(function (item) {
                                                return item.id === id_option;
                                            });
                                        }

                                        if (option) {
                                            if (this.form_type === 'delete' || this.form_type === 'view') {
                                                this.$set(this.fields[field_index], 'value', option[field.relation_fields]);
                                                //field.value = option[field.relation_fields];
                                            }
                                            else {
                                                this.$set(this.fields[field_index], 'value', option);
                                                //field.value = option;
                                            }
                                        }
                                        else {
                                            if (this.form_type === 'delete' || this.form_type === 'view') {
                                                this.$set(this.fields[field_index], 'value', '');
                                                //field.value = '';
                                            }
                                            else {
                                                this.$set(this.fields[field_index], 'value', {});
                                                //field.value = {};
                                            }
                                        }
                                    }
                                    else {
                                        if (typeof response.data.item[i] !== "undefined") {
                                            if (field.type === 'repeater') {
                                                let values = JSON.parse(JSON.stringify(response.data.item[i]));

                                                let items = [];

                                                for (let j in values) {
                                                    let repeater_fields = JSON.parse(JSON.stringify(field.fields));

                                                    let repeater_field_values = values[j];

                                                    for (let x in repeater_fields) {
                                                        if (typeof repeater_field_values[repeater_fields[x].name] !== 'undefined') {
                                                            repeater_fields[x].value = repeater_field_values[repeater_fields[x].name];
                                                        }
                                                    }

                                                    items.push({
                                                        repeater_unique_id: this.generateRandomId(),
                                                        repeater_removable: true,
                                                        fields: repeater_fields,
                                                    });
                                                }

                                                this.$set(this.fields[field_index], 'items', items);
                                            }
                                            else {
                                                this.$set(this.fields[field_index], 'value', response.data.item[i]);
                                            }
                                            //field.value = response.data.item[i];
                                        }
                                        else {
                                            this.$set(this.fields[field_index], 'value', '');
                                            //field.value = '';
                                        }
                                    }
                                }
                            }

                            //this.fields = JSON.parse(JSON.stringify(fields));
                            if (this.is_wizard) {
                                for (let i in this.steps) {
                                    for (let x in this.steps[i].formFields) {
                                        let fieldName = this.steps[i].formFields[x].name;

                                        let field = this.fields.find(function (item) {
                                            return item.name === fieldName;
                                        });

                                        if (field && typeof field.value !== "undefined") {
                                            this.$set(this.steps[i].formFields[x], 'value', JSON.parse(JSON.stringify(field.value)));
                                            //this.steps[i].formFields[x].value = JSON.parse(JSON.stringify(field.value));
                                        }
                                    }
                                }
                            }
                        }

                        this.$emit('values-loaded');

                        this.values_loaded = true;

                        this.loading = false;

                        this.scrollTop();

                        this.first_loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;

                        this.first_loading = false;

                        console.log(error);
                    })
            }
            this.loading = false;
        },

        submit: function (e) {
            e.preventDefault();
            this.doAction('save');
        },

        scrollTop() {
            if (!this.first_loading) {
                setTimeout(() => {
                    let top = this.$el.querySelector('.topform' + this.topFormClassId);
                    KTUtil.scrollTo(this.$el, 0, 500, -70);
                }, 100);
            }
        },

        generateRandomId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);

                return v.toString(16);
            });
        },
    },

    components: {
        FormFields,
    },
};
</script>
